<template>
  <div class="rechargeBox">
    <!-- 支付方式弹窗 -->
    <van-popup class="rechargePop" v-model="isOpenRecharge" position="bottom" @close="close">
      <div class="rechargeTitle">
        订单金额：
        <span>¥{{ discountedPrice }}</span>
        <!-- <svg-icon class="payClose" iconClass="payClose" @click.native="close"></svg-icon> -->
      </div>
      <!-- <div class="payMethod">支付方式：{{ payInfo.typeName }}</div> -->
      <!-- <div class="payMethod">支付方式：{{ payInfo.typeName }}</div> -->
      <div class="payyMethodTitle"></div>
      <van-radio-group v-model="activeItem" direction="horizontal" class="rechargeList">
        <div v-for="item in rechargeList" :key="item.productID" @click.stop="selectPay(item)">
          <div class="rechargeItem" v-if="item.type !== 'daichong'">
            <svg-icon class="payIcon" :iconClass="item.type"></svg-icon>
            <div class="payText">{{ item.typeName }}</div>
            <van-radio checked-color="rgb(255,103,143)" icon-size="16px" :name="item.type"></van-radio>
          </div>
          <!-- 代充 -->
          <div v-if="dcInfo.traders && dcInfo.traders.length > 0 && item.type === 'daichong'">
            <div v-for="dcItem in dcInfo.traders[0].payInfos" :key="dcItem.payMethod" @click="selectDcPay(item, dcItem)">
              <div class="rechargeItem" v-if="item.type == 'daichong'">
                <svg-icon class="payIcon" :iconClass="getSvgPayIcon(dcItem.payMethod)"></svg-icon>
                <div class="payText">
                  {{ getPayName(dcItem.payMethod) }}
                  <span>（人工充值）</span>
                </div>
                <van-radio checked-color="rgb(255,103,143)" icon-size="16px" :name="'dc' + dcItem.payMethod"></van-radio>
              </div>
            </div>
          </div>
        </div>
        <div class="rechargeItem" v-if="popType == 'vip' && isAmountPay" @click="selectGoldPay">
          <svg-icon class="payIcon" iconClass="vipGold"></svg-icon>
          <div class="payText">金币</div>
          <van-radio checked-color="rgb(255,103,143)" icon-size="16px" name="gold"></van-radio>
        </div>
      </van-radio-group>
      <div class="payTipTitle">
        支付小贴士：
        <span>（支付通道夜间比较忙碌，建议选择白天支付）</span>
      </div>
      <div class="payTip">1、跳转后请及时支付，超时支付无法到账，如多次支付失败，请尝试其他支付方式或稍后重试。</div>
      <div class="payTip">2、连续发起订单且未支付，账号可能被加入黑名单。支付成功后一般10分钟到账，若未到账请及时联系客服（24小时在线）</div>
      <div class="payTip">3、部分安卓手机支付时误报病毒，请选择忽略即可。如有疑问请及时联系客服（24小时在线）</div>
      <!-- <div class="customerService">
        支付遇到问题
        <router-link tag="span" to="/kefu">联系客服</router-link>
      </div> -->
      <div class="payBtn" @click="toPay">立即支付</div>
      <div class=""></div>
    </van-popup>

    <!-- 支付提示 -->
    <van-popup class="kindTips" v-model="showKindTips">
      <div class="kindTipsBox">
        <div class="tip">提示</div>
        <div class="redTip">
          粉柚收入来源于广告及赞助商，产品安全无毒，
          <span>可放心使用！</span>
        </div>
        <div class="redTip">
          由于APP包含成人敏感内容，会被判断为恶意软件。如在支付过程中遇到恶意软件提醒，请
          <span>点击忽略</span>
          即可。
        </div>
        <div class="tipBox">
          <div class="tipTop">
            <div class="logo"></div>
            <div class="infoBox">
              <div class="appName">粉柚</div>
              <div class="info">发现恶意程序请立即处理。</div>
            </div>
          </div>
          <div class="tipBottom">
            <div class="ignore">
              忽略
              <svg-icon class="arrow" iconClass="pinkArrow"></svg-icon>
              <span>点击这里</span>
            </div>
            <div class="uninstall">卸载</div>
          </div>
        </div>

        <div class="cBtn" @click="comfinPay">确认支付</div>
      </div>
    </van-popup>

    <!-- 订单确认弹窗 -->
    <van-popup class="payTipPop" v-model="showPayTip">
      <div class="tipItem">
        1.支付成功后，一般会在
        <span>1～10分钟到账</span>
        ，如超时未到账，请联系在线客服为你处理。
      </div>
      <div class="tipItem">2.受特殊行业限制，如支付失败可尝试重新发起订单，系统会自动为您处理。</div>
      <div class="tipItem">3.本APP有隐定的广告收益，产品稳定安全可放心使用。</div>
      <div class="knowed" @click="showPayTip = false">支付成功</div>
      <router-link tag="div" to="/kefu" class="kefu">支付遇到问题？</router-link>
    </van-popup>
  </div>
</template>
<script>
import { getLocalItem } from '@/utils/longStorage';
import { goldExchange, getPay } from '@/api/mine';
import { mapGetters } from 'vuex';
import { Tab, Tabs, Popup, RadioGroup, Radio, Toast, Field } from 'vant';
export default {
  name: 'RechargeBox',
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
  },
  props: {
    isRecharge: {
      type: Boolean,
      required: true,
      default: false,
    }, // 充值弹窗
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo',
    }),
  },
  data() {
    return {
      rechargeList: [], // 支付方式列表
      activeItem: '', // 支付方式选中
      dcInfo: '', // 代充信息
      dcType: 1, // 代充商品类型  会员-1 金币-0
      popType: 'vip', // 弹窗类型 会员卡-vip  金币-gold
      payMethodType: 'dc', // 支付方式类型  支付渠道-channel  代充-dc  金币兑换-gold
      productType: '', // 商品类型
      productID: '', // 商品ID
      buyType: '', // 商品类型
      discountedPrice: 0, // 充值金额
      payInfo: {}, // 支付渠道信息
      rchgType: {}, // 充值类型
      dcPayInfo: {}, // 代充支付信息
      showPayTip: false, // 支付提示弹窗状态
      showKindTips: false, // 温馨提示
      isAmountPay: false, // 是否支持金币兑换
      graphicsId: '',
      graphicsTitle: '',
      isOpenRecharge: false,
    };
  },
  watch: {
    isRecharge() {
      this.isOpenRecharge = this.isRecharge;
    },
  },
  methods: {
    /**
     * @description 显示支付方式弹窗
     * @param rechargeList - 支付方式列表
     * @param dcInfo - 代充信息
     * @param popType - 弹窗类型  会员卡-vip  金币-gold
     */
    changItem(payData) {
      this.rechargeList = payData.rechargeList;
      if (payData.dcInfo) {
        this.dcInfo = payData.dcInfo;
      }
      this.popType = payData.popType;
      this.dcType = payData.dcType;
      this.productType = payData.productType;
      this.productID = payData.productID;
      this.discountedPrice = payData.discountedPrice;
      this.buyType = payData.buyType;
      this.isAmountPay = payData.isAmountPay;
      let dcArr = this.rechargeList.filter((i) => {
        return i.type == 'daichong';
      });
      let channel = this.rechargeList.filter((i) => {
        return i.type != 'daichong';
      });
      if (channel.length > 0) {
        this.selectPay(this.rechargeList[0]);
      } else if (dcArr.length > 0 && this.dcInfo.traders.length > 0 && this.dcInfo.traders[0].payInfos.length > 0) {
        let arr = this.rechargeList.filter((i) => {
          return i.type == 'daichong';
        });
        this.selectDcPay(arr[0], this.dcInfo.traders[0].payInfos[0]);
      } else {
        this.selectGoldPay();
      }
    },
    changeTab(i) {
      if (i == 0) {
        this.$router.replace('/memberCentre?t=vip');
      } else {
        this.$router.replace('/myWallet');
      }
    },
    // 选中支付方式
    selectPay(item) {
      if (item.type != 'daichong') {
        this.payInfo = item;
        this.rchgType = {};
        this.dcPayInfo = {};
        this.payMethodType = 'channel';
        this.activeItem = item.type;
      }
    },
    // 选中代充支付方式
    selectDcPay(rchgType, dcPayInfo) {
      this.payInfo = {};
      this.rchgType = rchgType;
      this.dcPayInfo = dcPayInfo;
      this.payMethodType = 'dc';
      this.activeItem = 'dc' + this.dcPayInfo.payMethod;
    },
    // 选中金币兑换
    selectGoldPay() {
      this.payInfo = { typeName: '金币' };
      this.rchgType = {};
      this.dcPayInfo = {};
      this.payMethodType = 'gold';
      this.activeItem = 'gold';
    },
    // 立即支付
    toPay() {
      if (this.payMethodType == 'gold') {
        this.goldExchange();
        return;
      }
      this.showKindTips = true;
    },
    comfinPay() {
      if (this.payMethodType == 'channel') {
        this.channelPay();
      } else if (this.payMethodType == 'dc') {
        this.dcToPay();
      }
      this.$bus.$emit('dataBuriedEvent', {
        dataType: 3,
        graphicsType: this.$route.query['t'] == 'vip' ? 2 : 1,
        graphicsId: this.graphicsId,
        graphicsTitle: this.graphicsTitle,
      });
    },
    close() {
      this.$emit('closePay', false);
    },
    // 金币兑换
    async goldExchange() {
      let req = {
        productType: this.productType,
        productID: this.productID,
        isH5: true,
      };
      try {
        await this.$store.dispatch('user/getWallet');
        let walletInfo = this.$store.getters.walletInfo;
        let walletBalance = ((walletInfo.amount || 0) + (walletInfo.income || 0)).toFixed(2);
        if (this.discountedPrice > walletBalance) {
          Toast('金币不足请充值');
          return;
        }
        this.$store.commit('app/SET_LOADING', true);
        let ret = await this.$Api(goldExchange, req);
        if (ret && ret.code === 200) {
          this.$store.dispatch('user/getUserInfo');
          Toast('购买成功');
          this.$emit('closePay', false);
        } else if (ret.tip) {
          Toast(ret.tip || '购买失败，请稍后再试');
        }
        this.$store.commit('app/SET_LOADING', false);
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
      }
    },
    // 支付渠道支付
    async channelPay() {
      this.$store.commit('app/SET_LOADING', true);
      let req = {
        uid: this.userInfo.uid,
        rechargeType: this.payInfo.type,
        productID: this.productID,
        buyType: this.buyType,
      };
      try {
        let ret = await this.$Api(getPay, req);
        this.$store.commit('app/SET_LOADING', false);
        if (ret && ret.code === 200) {
          this.payInfo = {};
          this.showPayTip = true;
          this.showKindTips = false;
          setTimeout(() => {
            window.location = ret.data.payUrl;
            this.$store.dispatch('user/getUserInfo');
          }, 200);
        } else {
          Toast(ret.tip || '充值失败, 请稍后再试');
        }
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('充值失败, 请稍后再试');
      }
    },
    // 代充
    dcToPay() {
      let payMoney = this.discountedPrice;
      let dcData = JSON.parse(JSON.stringify(this.dcInfo)); // 代充信息
      let payInfoModel = JSON.parse(JSON.stringify(this.dcPayInfo)); // 代充支付类型
      let channel = this.rchgType.channel;
      let token = getLocalItem('token');
      let productInfo = {
        id: this.productID,
        type: this.dcType,
        version: '3.0.0',
      };
      //设置默认值
      if (dcData.limit == 0) {
        dcData.limit = 500;
      }
      if (payInfoModel.payType.includes(2) && payInfoModel.payType.includes(3)) {
        if (payMoney > dcData.limit) {
          payInfoModel.payType = [3];
        } else {
          payInfoModel.payType = [2];
        }
      }

      if (payInfoModel.payType.length >= 3) {
        if (payMoney > dcData.limit) {
          payInfoModel.payType = [1, 3];
        } else {
          payInfoModel.payType = [1, 2];
        }
      }
      dcData.traders[0].payInfos = [payInfoModel];
      dcData.productInfo = productInfo;
      dcData.chargeMoney = payMoney;
      dcData.channel = channel;
      let models = JSON.stringify(dcData);
      let payUrl = `${channel}/?data=${this.encodeBase64(models)}&token=${token}`;
      this.showPayTips = true;
      this.showPayTip = true;
      this.showKindTips = false;
      window.location = payUrl;
    },
    // 转base64
    encodeBase64(str) {
      let base64Str = Buffer.from(str, 'utf-8').toString('base64');
      return base64Str;
    },
    // 获取支付方式名称
    getPayName(type) {
      switch (type) {
        case 'alipay':
          return '支付宝';
        case 'wechat':
          return '微信';
        case 'union':
          return '银联';
        case 101:
          return '支付宝';
        case 102:
          return '微信';
        case 103:
          return '银行卡';
        case 104:
          return '信用卡';
        case 105:
          return '花呗';
        case 106:
          return '云闪付';
        case 107:
          return 'QQ钱包';
        case 108:
          return '京东支付';
        default:
          return '金币兑换';
      }
    },
    // 获取支付方式图标
    getSvgPayIcon(type) {
      switch (type) {
        case 'alipay':
          return 'alipay';
        case 'wechat':
          return 'wechat';
        case 'union':
          return 'bankcard';
        case 101:
          return 'alipay';
        case 102:
          return 'wechat';
        case 103:
          return 'bankcard';
        case 104:
          return 'bankcard';
        case 105:
          return 'alipay';
        case 106:
          return 'bankcard';
        case 107:
          return 'bankcard';
        case 108:
          return 'bankcard';
        default:
          return 'gold';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.rechargeBox {
  .rechargePop {
    border-radius: 6px 6px 0 0;
    background-color: rgb(255, 255, 255);
    padding: 0 12px;
    box-sizing: border-box;

    .rechargeTitle {
      // height: 60px;
      // line-height: 60px;
      font-size: 14px;
      margin: 20px 0 6px;
      text-align: center;
      color: #676767;
      // border-bottom: 1px solid rgb(239, 238, 245);
      // position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        color: #fd2d55;
        font-size: 28px;
      }
      .payClose {
        height: 22px;
        width: 22px;
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translate(0, -50%);
      }
    }

    .payMethod {
      font-size: 16px;
      margin-top: 12px;
    }
    .payyMethodTitle {
      width: 326px;
      height: 17px;
      margin: 0 auto;
      background-image: url('../../assets/png/payTitle.png');
      background-size: 100% 100%;
    }

    .rechargeList {
      max-height: 310px;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      padding: 8px 0px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-wrap: nowrap;
    }

    .rechargeItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 8px 0;
      .payIcon {
        width: 40px;
        height: 40px;
      }

      .payText {
        flex: 2;
        font-size: 14px;
        margin-left: 5px;
      }
    }

    .payTip {
      font-size: 12px;
      color: #8d8d8d;
      margin-bottom: 7px;
    }
    .payTipTitle {
      font-size: 14px;
      color: #8d8d8d;
      margin-bottom: 11px;
      span {
        font-size: 12px;
        color: #ff3e3e;
      }
    }

    .customerService {
      font-size: 12px;
      text-align: center;
      margin: 20px 0 6px;
      color: rgb(102, 102, 102);
      span {
        color: rgb(255, 103, 143);
      }
    }

    .payBtn {
      height: 38px;
      line-height: 38px;
      border-radius: 6px;
      font-size: 16px;
      text-align: center;
      color: #fff;
      background: rgb(255, 103, 143);
      margin: 10px 35px 30px 35px;
    }
  }
  .kindTips {
    border-radius: 8px;
    .kindTipsBox {
      width: 288px;
      height: 395px;
      background: url('./../../assets/png/kindTip.png') no-repeat;
      background-size: 100% 100%;
      padding: 87px 23px 0;
      box-sizing: border-box;
      .tip {
        font-size: 12px;
        color: rgb(85, 85, 85);
      }
      .redTip {
        color: #000;
        font-size: 12px;
        margin-bottom: 10px;
        span {
          color: rgb(255, 103, 143);
        }
      }

      .tipBox {
        width: 242px;
        height: 86px;
        padding: 13px 18px;
        border-radius: 10px;
        background-color: rgb(248, 248, 248);
        box-sizing: border-box;
        margin-top: 12px;
        .tipTop {
          display: flex;
          .logo {
            height: 37px;
            width: 37px;
            border-radius: 6px;
            background: url('./../../assets/png/appLogo.png') no-repeat;
            background-size: 100% 100%;
          }
          .infoBox {
            margin-left: 8px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            .appName {
              font-size: 14px;
            }
            .info {
              font-size: 11px;
              color: rgb(247, 74, 130);
            }
          }
        }
        .tipBottom {
          font-size: 11px;
          display: flex;
          margin-top: 10px;
          justify-content: space-between;
          .ignore {
            display: flex;
            align-items: center;
            justify-content: center;
            .arrow {
              width: 31px;
              margin: 0 3px;
            }
          }
          .uninstall {
            color: rgb(102, 102, 102);
          }
        }
      }

      .cBtn {
        height: 38px;
        width: 219px;
        background: linear-gradient(to right, rgb(247, 74, 130), rgb(252, 169, 132));
        font-size: 14px;
        color: white;
        text-align: center;
        line-height: 38px;
        border-radius: 10px;
        margin: 30px auto 0;
      }
    }
  }
  .payTipPop {
    overflow: visible;
    width: 288px;
    height: 429px;
    border-radius: 14px;
    padding: 149px 23px 0 30px;
    box-sizing: border-box;
    background: url('./../../assets/png/payConfirm.png') no-repeat;
    background-size: 100% 100%;
    .tipItem {
      font-size: 12px;
      margin-bottom: 16px;
      color: rgb(82, 82, 82);
      line-height: 20px;
      span {
        color: rgb(255, 103, 143);
      }
    }

    .knowed {
      margin: 29px auto 0;
      height: 38px;
      width: 219px;
      background: linear-gradient(to right, rgb(247, 74, 130), rgb(252, 169, 132));
      color: white;
      text-align: center;
      line-height: 38px;
      border-radius: 10px;
    }
    .kefu {
      font-size: 14px;
      color: rgb(145, 145, 145);
      text-decoration: underline;
      margin: 20px auto 0;
      display: inline-block;
      text-align: center;
      width: 100%;
    }
  }
}
/deep/ .van-popup {
  background-color: unset;
}
</style>
